.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

@keyframes loader-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: loader-animation 2s linear infinite;
}

/*.sidebar-body .nav-label {*/
/*    font-size: 14px !important;*/
/*    font-weight: bold;*/
/*}*/

.larger-icon {
    font-size: 30px;
}

@media (min-width: 768px)
.customize-label-class {
    width: 10% !important;
}
